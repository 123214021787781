<template>
    <div :class="[flag ? '' : 'content-box']">
        <div :class="[flag ? '' : 'container']">
            <div :class="[flag ? 'contentM' : 'content']">
                <div v-if="!flag" class="content_row">
                    <h4 class="global_head_title">进件详情</h4>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">关联客户：</label>
                    <div>{{ name }}</div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">进件类型：</label>
                    <div class="content_right">
                        <a-radio-group disabled name="radioGroup" v-model="type">
                            <a-radio :value="1">
                                外卖
                            </a-radio>
                            <a-radio :value="2">
                                小时达
                            </a-radio>
                        </a-radio-group>
                    </div>
                </div>
                <div class="content_row">
                    <label class="info" for="">营业执照：</label>
                    <div class="content_right">
                        <img v-if="license" :src="url + '/scrm/index/getFile?id=' + license">
                    </div>
                </div>
                <div class="content_row">
                    <label class="info" for="">食品经营许可证：</label>
                    <div class="content_right">
                        <img v-if="food_license" :src="url + '/scrm/index/getFile?id=' + food_license">
                    </div>
                </div>
                <div class="content_row">
                    <label class="info" for="">门头照（两张）：</label>
                    <div class="content_right">
                        <template v-for="(o, i) in doorstep_photo">
                            <img :key="i" v-if="o" :src="url + '/scrm/index/getFile?id=' + o"
                                style="margin-bottom:10px">
                        </template>
                    </div>
                </div>
                <div class="content_row">
                    <label class="info" for="">法人身份证正面照片：</label>
                    <div class="content_right">
                        <img v-if="idcard_front" :src="url + '/scrm/index/getFile?id=' + idcard_front">
                    </div>
                </div>
                <div class="content_row">
                    <label class="info" for="">法人身份证反面照片：</label>
                    <div class="content_right">
                        <img v-if="idcard_back" :src="url + '/scrm/index/getFile?id=' + idcard_back">
                    </div>
                </div>
                <div v-if="type == 1">
                    <div class="content_row">
                        <label class="info" for="">抖音来客门店管理中截图：</label>
                        <div class="content_right">
                            <img v-if="tiktok_screenshot" :src="url + '/scrm/index/getFile?id=' + tiktok_screenshot">
                        </div>
                    </div>

                    <div class="content_row flex">
                        <label class="info" for="">法人实名手机号：</label>
                        <div class="content_right">
                            <div>{{ legal_phone }}</div>
                        </div>
                    </div>
                    <div class="content_row flex">
                        <label class="info" for="">法人支付宝账号：</label>
                        <div class="content_right">
                            <div>{{ legal_zfb }}</div>
                        </div>
                    </div>
                    <div class="content_row">
                        <label class="info" for="">单商品图片（两张）：</label>
                        <div class="content_right">
                            <template v-for="(o, i) in commodity_photo">
                                <img :key="i" v-if="o" :src="url + '/scrm/index/getFile?id=' + o"
                                    style="margin-bottom:10px">
                            </template>
                        </div>
                    </div>
                    <div class="content_row flex">
                        <label class="info" for="">商品外卖价格：</label>
                        <div class="content_right">
                            <div>{{ commodity_price }}</div>
                        </div>
                    </div>
                    <div class="content_row flex">
                        <label class="info" for="">结算银行卡卡号：</label>
                        <div class="content_right">
                            <div>{{ bank_account }}</div>
                        </div>
                    </div>
                    <div class="content_row flex">
                        <label class="info" for="">结算银行卡开户行：</label>
                        <div class="content_right">
                            <div>{{ bank }}</div>
                        </div>
                    </div>
                </div>

                <div v-if="type == 2">
                    <div class="content_row">
                        <label class="info" for="">室内实景照片（3张）：</label>
                        <div class="content_right">
                            <template v-for="(o, i) in in_store_photo">
                                <img :key="i" v-if="o" :src="url + '/scrm/index/getFile?id=' + o"
                                    style="margin-bottom:10px">
                            </template>
                        </div>
                    </div>
                    <div class="content_row flex">
                        <label class="info" for="">门店收款账户银行：</label>
                        <div class="content_right">
                            <div>{{ store_receiving_account_bank }}</div>
                        </div>
                    </div>
                    <div class="content_row flex">
                        <label class="info" for="">开户支行：</label>
                        <div class="content_right">
                            <div>{{ opening_branch }}</div>
                        </div>
                    </div>
                    <div class="content_row flex">
                        <label class="info" for="">开户预留手机号码：</label>
                        <div class="content_right">
                            <div>{{ opening_phone }}</div>
                        </div>
                    </div>
                    <div class="content_row flex">
                        <label class="info" for="">法人手机号码：</label>
                        <div class="content_right">
                            <div>{{ legal_phone }}</div>
                        </div>
                    </div>
                    <div class="content_row flex">
                        <label class="info" for="">开通小店登录手机号码：</label>
                        <div class="content_right">
                            <div>{{ open_store_phone }}</div>
                        </div>
                    </div>
                    <div class="content_row flex">
                        <label class="info" for="">营业时间：</label>
                        <div class="content_right">
                            <div>{{ business_hours }}</div>
                        </div>
                    </div>
                    <div class="content_row flex">
                        <label class="info" for="">实际经营地址：</label>
                        <div class="content_right">
                            <div>{{ address }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import $ from "jquery";
import { requestXml, isMobile, fileUpload, ajaxUrl } from '../../../assets/js/request';
var url, delUrl;
export default {
    props: {
        addFlag: {
            type: Boolean,
            default: false
        },
        orderId: {
            type: String
        }
    },
    data() {
        return {
            flag: true,
            order: {},

            url: ajaxUrl,
            // 订单id
            id: "",
            // 类型
            type: 1,
            // 进件编号
            osn: "",
            //手机号，支付宝
            legal_phone: '',
            legal_zfb: '',
            //商品价格，银行，卡号
            commodity_price: '',
            bank: '',
            bank_account: '',
            //商户名称
            // tmjcpb_corp__name: '',
            // tmjcpb_corps__zd1719102503:'',
            // tmjcpb_corps__zd1719102509:'',
            //门店收款账户银行,开户支行,开户预留手机号码
            store_receiving_account_bank: '',
            opening_branch: '',
            open_store_phone: '',
            //营业时间,实际经营地址
            business_hours: '',
            address: '',
        };
    },
    mounted: function () {
        // this.getfield();
        // 如果有id则是编辑 获取详情
        if (this.$route.query.id) {
            this.id = this.$route.query.id;
            this.getOrderDetail();
            this.getStaffList();
        }

        if (this.$route.query.type) {
            this.type = this.$route.query.type;
            if (this.type == 3) {
                this.repVisible = true;
                this.visible = false;
            } else {
                this.repVisible = false;
                this.visible = true;
            }
        }
        if (isMobile()) {
            this.flag = true;
        } else {
            this.flag = false;
        }
        // this.getSet();
    },

    methods: {
        // 获取订单自定义字段
        getfield() {
            requestXml("/scrm/order/getfield", "GET", (res) => {
                console.log(res)
                this.orderField = res;
            })
        },
        // 获取订单详情
        getOrderDetail() {
            requestXml("/scrm/corp/getPurchaseDetails", "GET", (res) => {
                this.type = res.type;
                this.osn = res.osn;
                this.legal_phone = res.legal_phone;
                this.legal_zfb = res.legal_zfb;
                this.commodity_price = res.commodity_price;
                this.bank = res.bank;
                this.name = res.name;
                this.bank_account = res.bank_account;
                this.opening_phone = res.opening_phone;
                // this.tmjcpb_corp__name = res.tmjcpb_corp__name;
                // this.tmjcpb_corps__zd1719102503 = res.tmjcpb_corps__zd1719102503;
                // this.tmjcpb_corps__zd1719102509 = res.tmjcpb_corps__zd1719102509;
                this.store_receiving_account_bank = res.store_receiving_account_bank;
                this.opening_branch = res.opening_branch;
                this.open_store_phone = res.open_store_phone;
                this.business_hours = res.business_hours;
                this.address = res.address;
                this.idcard_front = res.idcard_front;
                this.idcard_back = res.idcard_back;
                this.license = res.license;
                this.food_license = res.food_license;
                this.tiktok_screenshot = res.tiktok_screenshot;
                this.doorstep_photo = res.doorstep_photo.split(',');
                this.commodity_photo = res.commodity_photo.split(',');
                this.in_store_photo = res.in_store_photo.split(',');
            }, { id: this.id })
        },
        // 获取收款人
        getStaffList() {
            requestXml("/scrm/Staff/getMinList", "GET", (res) => {
                this.handlerList = res;
                // console.log(this.handlerList)
            })
        },
        // 获取退款原因
        getSet() {
            requestXml("/scrm/setting/getSetting", "GET", (res) => {
                console.log(res.invalid, 888888)
            })
        },
        // 添加收款和退款
        addRecord(type) {
            console.log(type)
            this.visible = true;
            this.type = type;
            if (type == 1) {
                this.title = "添加收款";
                this.methodText = "收款";
                url = "/scrm/Order/addPayment";
            } else {
                this.title = "添加退款";
                this.methodText = "退款";
                url = "/scrm/Order/addRefund";
            }
        },
        handleOk() {
            this.record.oid = this.id;
            if (!this.record.amount) {
                this.$message.warning('请填写金额');
                return false;
            }
            if (!this.record.method) {
                this.$message.warning('请选择方式');
                return false;
            }
            if (this.type == 1) {
                this.record.receipt_time = this.record.receipt_time._d.getTime();
                if (!this.record.receipt_time) {
                    this.$message.warning('请选择收款时间');
                    return false;
                }
            }
            if (!this.record.staff_uid) {
                this.$message.warning('请选择收款人');
                return false;
            }

            requestXml(url, "POST", (res) => {
                console.log(res)
                if (res == "error") {
                    this.visible = false;
                } else {
                    this.visible = false;
                    this.record = {
                        oid: 0,
                        amount: null,
                        method: 1,
                        receipt_time: "",
                        staff_uid: "default",
                        doc: "",
                    },
                        this.getOrderDetail();
                }
            }, this.record)
        },
        // 删除
        onDelete(id, type) {
            if (type == 1) {
                delUrl = "/scrm/Order/delPayment";
            } else {
                delUrl = "/scrm/Order/delRefund";
            }
            requestXml(delUrl, "POST", () => {
                this.getOrderDetail();
            }, { "id": id })
        },
        // 添加补充条款
        addReplenish() {
            this.repVisible = true;
        },
        handleOk2() {
            this.repRecord.oid = this.id;
            if (!this.repRecord.amount) {
                this.$message.warning('请填写金额');
                return false;
            }
            if (!this.repRecord.sign_time) {
                this.$message.warning('请选择签订时间');
                return false;
            } else {
                this.repRecord.sign_time = this.repRecord.sign_time._d.getTime();
            }

            requestXml("/scrm/Order/addReplenish", "POST", (res) => {
                console.log(res)
                if (res == "error") {
                    this.repVisible = false;
                } else {
                    this.repVisible = false;
                    this.repRecord = {
                        oid: 0,
                        amount: null,
                        sign_time: "",
                        quotation: 0,
                        contract: 0,
                        content: ""
                    },
                        this.getOrderDetail();
                }
            }, this.repRecord)
        },
        // 报价方案
        quoUpload(e) {
            fileUpload(e, (res) => {
                console.log(res)
                this.repRecord.quotation = res.id;
                this.repRecord.quotationText = res.name;
            })
        },
        // 订单
        conUpload(e) {
            fileUpload(e, (res) => {
                this.repRecord.contract = res.id;
                this.repRecord.contractText = res.name;
            })
        },
    }
};
</script>
<style scoped>
.action {
    display: inline-block;
    padding: 5px 10px;
    margin: 0 5px;
    background: #DCEEFF;
    border: 1px solid #36A3FF;
    color: #36A3FF;
    border-radius: 5px;
}

.del {
    background: #FFDCDC;
    border-color: #FF4C4C;
    color: #FF4C4C;
}

.ant-list {
    padding: 0 3.5rem;
}

.ant-list .content_row,
.ant-list .content_rowM {
    justify-content: space-between;
}

.ant-modal .content_row {
    margin: 2rem 0;
    font-size: 16px;
    align-items: center;
}

.ant-modal .content_row .info {
    width: 17rem;
    padding-right: 10px;
}

.ant-modal .content_row .content_right {
    width: calc(100vw - 25rem);
}
.content_right img{
    width: 180px;
}

.ant-list .ant-list-item {
    background: #fff;
    border: 0;
    border-radius: 10px;
    margin: 2rem 0;
}

.contentM{
    background: #fff !important;
}
.contentM .content_row {
    align-items: center;
    /* height: 7rem; */
    line-height: 7rem;
    padding: 0 2.5rem;
    /* font-weight: 700; */
    background: #fff;
    color: #000;
    border-bottom: 1px solid #eee;
}

.contentM .content_row .info {
    display: inline-block;
    /* width: 30rem; */
    white-space: nowrap;
    text-align: left;
    padding-right: 2rem;
    font-weight: bold;
}

.contentM .content_rowM {
    padding: 1rem 0;
    color: #202020;
}

.progres {
    align-items: center;
    margin: 2.5rem 3.5rem;
}

/* input ,selsct */
.ant-input,
.ant-select,
.ant-calendar-picker {
    width: calc(90vw - 20rem);
}

.ant-list .content_row {
    justify-content: space-between;
}

.fileLabel {
    line-height: 1.499;
    font-weight: 400;
    background-image: none;
    border: 1px solid #d9d9d9;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    cursor: pointer;
    width: 90px;
    height: 32px;
    line-height: 32px;
    padding: 5px 15px;
    /* font-size: 14px; */
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.65);
    margin: 0 !important;
}

input[type="file"] {
    display: none;
}

@media screen and (min-width: 750px) {
    .content_right img{
        width: 300px;
    }
    .ant-modal .content_row .info {
        width: 200px;
        padding-right: 10px;
    }

    .content_row {
        margin: 15px 0;
    }

    .content_row .info {
        width: 200px;
        padding-right: 10px;
        float: left;
    }

    .content_row .content_right {
        width: auto;
    }

    .content .content_row .editable-add-btn {
        margin-bottom: 15px;
    }

    .content .content_row .ant-calendar-picker input {
        width: 100% !important;
    }

    /* input ,selsct */
    .ant-input,
    .ant-select,
    .ant-calendar-picker,
    .ant-input-affix-wrapper {
        width: 300px;
    }

    /* .ant-modal{
            width: 550px !important;
        } */
    .save .ant-btn {
        margin-left: 90px;
    }
}
</style>
