<template>
    <div class="box page">
        <to-history :prop-message="title"></to-history> 
        <detail :orderId="orderId"></detail>
    </div>
    
</template>

<script>
import detail from '../../scrm_pc/client/purchaseDetail'  
export default {
    components: {
        'detail': detail  //将别名demo 变成 组件 Demo
    },
    data() {
        return {
            orderId: "",
            title:'详情'
        }
    },
    mounted: function(){
        console.log(this.$route.query.id);
        this.orderId = this.$route.query.id;
    },
}

</script>
<style scoped>
    
.page {
    background-image: url('../../../assets/imgs/wap/bg.png');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% auto;
    padding-bottom: 50px;
}
.contentM{
    background: #fff !important;
}
</style>
